export const cognito = {
  cognitoUserPoolId: 'eu-west-1_bT2hzoFuI',
  cognitoIdentityPoolId: 'eu-west-1:771ca782-d686-4bff-815a-26c1f3890922',
  cognitoAppClientId: '49b6455b1emehs6akmn57hcnc1',
  cognitoUserPoolDomain: 'theexeter-member-uat.auth.eu-west-1.amazoncognito.com',
  cognitoRegion: 'eu-west-1',
};

export const googleAnalyticsTag = 'G-PD0M2WX1H9';
export const closedClaimsDisplayDate = '2024-12-01T00:00:00Z';

export const featureFlags = {
  staticClaimGuide: true,
};

export default {};
